// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bio-js": () => import("./../../../src/pages/bio.js" /* webpackChunkName: "component---src-pages-bio-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contacto-gracias-js": () => import("./../../../src/pages/contacto-gracias.js" /* webpackChunkName: "component---src-pages-contacto-gracias-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instagram-js": () => import("./../../../src/pages/instagram.js" /* webpackChunkName: "component---src-pages-instagram-js" */),
  "component---src-pages-limpieza-js": () => import("./../../../src/pages/limpieza.js" /* webpackChunkName: "component---src-pages-limpieza-js" */),
  "component---src-pages-linktree-js": () => import("./../../../src/pages/linktree.js" /* webpackChunkName: "component---src-pages-linktree-js" */),
  "component---src-pages-novedades-js": () => import("./../../../src/pages/novedades.js" /* webpackChunkName: "component---src-pages-novedades-js" */),
  "component---src-pages-tarot-js": () => import("./../../../src/pages/tarot.js" /* webpackChunkName: "component---src-pages-tarot-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */)
}

